import React, { useEffect, useState } from "react";
import { SetValueConfig, UseFormGetValues, UseFormSetValue } from "react-hook-form";
import { FaRegEdit } from "@react-icons/all-files/fa/FaRegEdit";
import { MdDelete } from "@react-icons/all-files/md/MdDelete";
import { FaUndo } from "@react-icons/all-files/fa/FaUndo";

import { Box, Button, Flex, Text, IconButton, Stack, NewDivider } from "Atoms";
import { Modal, ModalActions, ModalBody, ModalCloseBtn, ModalHeader } from "Molecules";
import { useLanguage } from "LanguageProvider";
import { CountryLocaleId, DiscountMenu, MenuCategoryProducts, CampaignDiscountShop } from "Types";
import { Campaign, CampaignQuery } from "../types/types";
import { modalNames } from "Constants";
import { Tab, TabList, TabPanel, TabPanels, Tabs } from "Organisms";
import { useQoplaStore } from "Stores/qoplaStore";
import { GET_ALCOHOL_PRODUCT_IDS } from "GraphQLQueries";
import { useMothershipQuery } from "Hooks";
import { MenuProductList } from "./MenuProductList";
import { getFullMenuCategoryProductIds, isFullMenuSelected } from "../utils/campaignFunctions";

type ModalProps = {
    modalContent: {
        menus: DiscountMenu[];
        shopDetails: CampaignDiscountShop[];
        formContext?: { getValues: UseFormGetValues<Campaign>; setValue: UseFormSetValue<Campaign> };
        editMenuContent?: {
            selectedMenuIds: string[];
            menuProductCategoryIds: MenuCategoryProducts[];
            updateCampaignMenus: (menuProductCategoryIds: MenuCategoryProducts[]) => void;
        };
    };
    closeModal: (modal: string) => void;
};

enum MENU_STATUS {
    /** initial setup state */
    ALL_SELECTED = "ALL_SELECTED",
    /** If whole menu is deselected it goes into edit mode */
    EDIT = "EDIT",
    /** As soon as a category or product gets selected */
    ITEMS_SELECTED = "ITEMS_SELECTED"
}

type MenuSelectionStatus = {
    id: string;
    status: MENU_STATUS;
    preventSave: boolean;
    markedAsRemove: boolean;
    isFullMenu: boolean;
};

export const CampaignMenuSelectionModal: React.FC<ModalProps> = ({ modalContent, closeModal }) => {
    /** Current selected menus, categories & products */
    const [menuProductCategoryIds, setMenuProductCategoryIds] = useState<MenuCategoryProducts[]>([]);
    /** Keeps track of the status of the menu in the modal see MENU_STATUS for which ones */
    const [menuCheckStatus, setMenuCheckStatus] = useState<MenuSelectionStatus[]>([]);

    const { translate } = useLanguage();

    const {
        companyLocale,
        selectedCompany: {
            id: companyId,
            settings: { sellingAlcohol = false }
        }
    } = useQoplaStore();

    const isSwedishCompany = (companyLocale?.baseName || CountryLocaleId.sv_SE) === CountryLocaleId.sv_SE;
    const isSwedishCompanyAndSellsAlcohol = isSwedishCompany && sellingAlcohol;

    const { data: alcoholProducts, loading: loadingAlcoholProducts } =
        useMothershipQuery<CampaignQuery.AlcoholProductIds>(GET_ALCOHOL_PRODUCT_IDS, {
            variables: { companyId: companyId },
            skip: !isSwedishCompanyAndSellsAlcohol
        });

    const { menus, formContext, editMenuContent } = modalContent;

    const isEditMenu = !!editMenuContent?.selectedMenuIds;

    const selectedMenuIds = !isEditMenu ? formContext?.getValues("selectedMenuIds") : editMenuContent?.selectedMenuIds;
    const selectedMenuProducts = !isEditMenu
        ? formContext?.getValues("menuCategoryAndProductIds")
        : editMenuContent?.menuProductCategoryIds;

    const selectedMenus = menus?.filter(menu => selectedMenuIds?.includes(menu.id));
    const alcoholProductIds = alcoholProducts?.getAlcoholProductIds ?? [];

    const onHandleCloseModal = () => {
        closeModal(modalNames.CAMPAIGN_MENU_SELECTION_MODAL);
    };

    /**
     * On save add to the form context the selected menu ids and the selected menu products
     */
    const onHandleSave = () => {
        const removeMenus = menuCheckStatus.filter(menu => menu.markedAsRemove).map(menu => menu.id);
        const saveOption: Partial<SetValueConfig> = { shouldDirty: true, shouldValidate: true };
        if (!!removeMenus.length) {
            if (!isEditMenu) {
                formContext?.setValue(
                    "selectedMenuIds",
                    selectedMenuIds?.filter(menuId => !removeMenus.includes(menuId)),
                    saveOption
                );
                formContext?.setValue(
                    "menuCategoryAndProductIds",
                    menuProductCategoryIds.filter(menu => !removeMenus.includes(menu.menuId)),
                    saveOption
                );
            } else {
                editMenuContent.updateCampaignMenus(
                    menuProductCategoryIds.filter(menu => !removeMenus.includes(menu.menuId))
                );
            }
        } else {
            !isEditMenu && formContext?.setValue("menuCategoryAndProductIds", menuProductCategoryIds, saveOption);
            isEditMenu && editMenuContent.updateCampaignMenus(menuProductCategoryIds);
        }
        onHandleCloseModal();
    };

    /**
     * [FUNCTION] get all product ids under a category in a particular menu
     * @param categoryId specific id
     * @param menuId  id of the menu to look in
     */
    const getProductIdsUnderCategoryInMenu = (categoryId: string, menuId: string) => {
        const menu = menus.find(menu => menu.id === menuId);
        const category = menu?.menuProductCategories.find(cat => cat.id === categoryId);
        return [...(category?.menuBundleProducts ?? []), ...(category?.menuProducts ?? [])].map(product => product.id);
    };

    /**
     * [FUNCTION] Toggle the category in the selected menu
     * @param categoryId
     * @param isChecked
     * @param menuId
     */
    const onToggleMenuCategory = (categoryId: string, isChecked: boolean, menuId: string) => {
        const menuProductCategory = menuProductCategoryIds.find(menu => menu.menuId === menuId);
        if (menuProductCategory && isChecked) {
            const newProductMenuIds = menuProductCategoryIds.map(menu => {
                if (menu.menuId === menuId) {
                    return {
                        ...menu,
                        categoryIds: menu.categoryIds.filter(id => id !== categoryId),
                        productIds: menu.productIds.filter(
                            id => !getProductIdsUnderCategoryInMenu(categoryId, menuId).includes(id)
                        )
                    };
                }
                return menu;
            });
            setMenuProductCategoryIds(newProductMenuIds);
        } else {
            const updatedMenuProductCategory = menuProductCategoryIds.map(menu => {
                if (menu.menuId === menuId) {
                    return {
                        ...menu,
                        categoryIds: [...menu.categoryIds, categoryId],
                        productIds: [...menu.productIds].filter(
                            id => !getProductIdsUnderCategoryInMenu(categoryId, menuId).includes(id)
                        )
                    };
                }
                return menu;
            });
            setMenuProductCategoryIds(updatedMenuProductCategory);
            setCurrentMenuSelectionStatus(menuId, MENU_STATUS.ITEMS_SELECTED);
        }
    };

    const setCurrentMenuStatusToRemove = (menuId: string) => {
        const updatedMenuStatus = menuCheckStatus.map(menu => {
            if (menu.id === menuId) {
                if (menu.markedAsRemove) {
                    const menuProductSelection = menuProductCategoryIds.find(menu => menu.menuId === menuId);
                    const menuStatus = isFullMenuSelected(menuId, menus, menuProductSelection?.categoryIds ?? [])
                        ? MENU_STATUS.ALL_SELECTED
                        : MENU_STATUS.ITEMS_SELECTED;
                    return {
                        ...menu,
                        status: menuStatus,
                        markedAsRemove: false,
                        preventSave: false
                    };
                } else {
                    return {
                        ...menu,
                        status: MENU_STATUS.ALL_SELECTED,
                        markedAsRemove: true,
                        preventSave: false
                    };
                }
            }
            return menu;
        });
        setMenuCheckStatus(updatedMenuStatus);
    };

    /**
     * [FUNCTION] - Toggle the product(s) in the selected menu
     * @param categoryId
     * @param productId
     * @param isChecked
     * @param menuId
     */
    const onToggleMenuProduct = (categoryId: string, productId: string, isChecked: boolean, menuId: string) => {
        /* Pass in is the state of the checkbox - which will be opposite to here it was false so it will add product to list */
        const addProduct = !isChecked;
        if (addProduct) {
            const menuWithAddedProductIds = menuProductCategoryIds.map(menu => {
                if (menu.menuId === menuId) {
                    const selectedProductIds = [...menu.productIds, productId];
                    const productsInCategory = getProductIdsUnderCategoryInMenu(categoryId, menuId);
                    /** Check that all products selected are under category if they are then select category as a whole */
                    const hasAllProductsSelected = productsInCategory.every(id => selectedProductIds.includes(id));
                    const categoryIds = hasAllProductsSelected ? [...menu.categoryIds, categoryId] : menu.categoryIds;

                    return {
                        ...menu,
                        productIds: hasAllProductsSelected
                            ? [...menu.productIds.filter(id => !productsInCategory.includes(id))]
                            : selectedProductIds,
                        categoryIds: categoryIds
                    };
                }
                return menu;
            });
            setMenuProductCategoryIds(menuWithAddedProductIds);
            setCurrentMenuSelectionStatus(menuId, MENU_STATUS.ITEMS_SELECTED);
        } else {
            const menuWithProductId = menuProductCategoryIds.map(opt => ({
                ...opt,
                productIds: opt.productIds.filter(id => id !== productId)
            }));
            setMenuProductCategoryIds(menuWithProductId);
        }
    };

    /** Set menu status in modal
     * 1. If all products are selected in the menu then set status to "ALL_SELECTED" or Top checkbox is checked
     * 2. If some products are selected in the menu then set status to "ITEMS_SELECTED"
     * 3. If no products are selected in the menu then set status to "EDIT" (so uncheck the top checkbox)
     */
    const setCurrentMenuSelectionStatus = (menuId: string, menuStatus: MENU_STATUS) => {
        const updatedMenuStatus = menuCheckStatus.map(menu => {
            if (menu.id === menuId) {
                return {
                    ...menu,
                    id: menuId,
                    status: menuStatus,
                    preventSave: menuStatus === MENU_STATUS.EDIT,
                    markedAsRemove: false
                };
            }
            return menu;
        });
        setMenuCheckStatus(updatedMenuStatus);
    };

    /**
     * [FUNCTION] - Toggle the menu status
     * @param menuId
     * @param isChecked
     */
    const onToggleMenuStatus = (menuId: string, isChecked: boolean) => {
        if (!isChecked) {
            const menuWithoutAddedProducts = menuProductCategoryIds.map(menu => {
                if (menu.menuId === menuId) {
                    return getFullMenuCategoryProductIds(menuId, menus, new Set(alcoholProductIds));
                }
                return menu;
            });
            setMenuProductCategoryIds(menuWithoutAddedProducts);
            setCurrentMenuSelectionStatus(menuId, MENU_STATUS.ALL_SELECTED);
        } else {
            const selectedMenu = menuProductCategoryIds.find(menu => menu.menuId === menuId);

            const menu = selectedMenus?.find(menu => menu.id === menuId);
            const allCategoriesSelected = menu?.menuProductCategories?.length === selectedMenu?.categoryIds.length;

            if (!!selectedMenu && selectedMenu.categoryIds.length === 0 && selectedMenu.productIds.length === 0) {
                setCurrentMenuSelectionStatus(menuId, MENU_STATUS.EDIT);
            }

            if (allCategoriesSelected) {
                const menuWithoutAddedProducts = menuProductCategoryIds.map(menu => {
                    if (menu.menuId === menuId) {
                        /** This is an emptry menu with no selection - ready for editing */
                        return {
                            menuId: menuId,
                            categoryIds: [],
                            productIds: []
                        };
                    }
                    return menu;
                });
                setMenuProductCategoryIds(menuWithoutAddedProducts);
                setCurrentMenuSelectionStatus(menuId, MENU_STATUS.EDIT);
            }
        }
    };

    /**
     * [FUNCTION] - Clear all menu selection
     * - Clear all the selected products and categories in the menu
     * @param menuId
     * @returns
     */
    const onClearAllMenuSelection = (menuId: string) => {
        const menuWithoutAddedProducts = menuProductCategoryIds.map(menu => {
            if (menu.menuId === menuId) {
                /** This is an empty menu with no selection - ready for editing */
                return {
                    menuId: menuId,
                    categoryIds: [],
                    productIds: []
                };
            }
            return menu;
        });
        setMenuProductCategoryIds(menuWithoutAddedProducts);
        setCurrentMenuSelectionStatus(menuId, MENU_STATUS.EDIT);
    };

    /**
     * [FUNCTION] - Initial setup of the menu selection
     * - If form has selectedMenuIds and selectedMenuProducts then setup the menu selection
     */
    const onInitialMenuSelectionSetup = () => {
        if (!!selectedMenuProducts && !!selectedMenuIds) {
            const updatedMenuProducts: MenuCategoryProducts[] = selectedMenuProducts.filter(menu =>
                selectedMenuIds?.includes(menu.menuId)
            );
            const hasMoreSelected = (selectedMenuIds?.length ?? 0) > updatedMenuProducts.length;

            let missingMenusSelections: MenuSelectionStatus[] = [];
            let missingMenuCateogryProducts: MenuCategoryProducts[] = [];
            if (hasMoreSelected) {
                const missingMenus = selectedMenuIds.filter(
                    menuId => !updatedMenuProducts.some(menu => menu.menuId === menuId)
                );

                missingMenuCateogryProducts = missingMenus.map(menuId => {
                    return getFullMenuCategoryProductIds(menuId, menus, new Set(alcoholProductIds));
                });

                missingMenusSelections = missingMenus.map(menuId => {
                    const isFullMenu = isFullMenuSelected(
                        menuId,
                        menus,
                        missingMenuCateogryProducts.find(menu => menu.menuId === menuId)?.categoryIds ?? []
                    );
                    return {
                        id: menuId,
                        status: !isFullMenu ? MENU_STATUS.ITEMS_SELECTED : MENU_STATUS.ALL_SELECTED,
                        preventSave: false,
                        markedAsRemove: false,
                        isFullMenu: isFullMenu
                    };
                });
            }
            const menuStatus: MenuSelectionStatus[] = updatedMenuProducts.map(menu => {
                const isFullMenu = isFullMenuSelected(menu.menuId, menus, menu.categoryIds);
                return {
                    id: menu.menuId,
                    status: isFullMenu ? MENU_STATUS.ALL_SELECTED : MENU_STATUS.ITEMS_SELECTED,
                    preventSave: false,
                    markedAsRemove: false,
                    isFullMenu: isFullMenu
                };
            });
            const menuCheckStatus = [...menuStatus, ...missingMenusSelections];
            const menuSelection = [...selectedMenuProducts, ...missingMenuCateogryProducts];
            setMenuCheckStatus(menuCheckStatus);
            setMenuProductCategoryIds(menuSelection);
        }
    };

    useEffect(() => {
        if (!!selectedMenuIds && !selectedMenuProducts && !loadingAlcoholProducts) {
            let fullMenusSelected = [] as string[];
            const menuProductCategoryIds = selectedMenuIds.map(menuId => {
                const fullMenuSelection = getFullMenuCategoryProductIds(menuId, menus, new Set(alcoholProductIds));
                const isFullMenu = isFullMenuSelected(menuId, menus, fullMenuSelection.categoryIds);
                if (isFullMenu) {
                    fullMenusSelected = [...fullMenusSelected, menuId];
                }
                return fullMenuSelection;
            });
            setMenuProductCategoryIds(menuProductCategoryIds);
            const menuStatus = selectedMenuIds.map(menuId => {
                const fullMenu = fullMenusSelected.includes(menuId);
                return {
                    id: menuId,
                    status: fullMenu ? MENU_STATUS.ALL_SELECTED : MENU_STATUS.ITEMS_SELECTED,
                    preventSave: false,
                    markedAsRemove: false,
                    isFullMenu: fullMenu
                };
            });
            setMenuCheckStatus(menuStatus);
        }
        !loadingAlcoholProducts && onInitialMenuSelectionSetup();
    }, [loadingAlcoholProducts]);

    const preventSaving = menuCheckStatus.some(menu => menu.preventSave);
    const allAreMarkedRemove = menuCheckStatus.every(menu => menu.markedAsRemove);
    const itemsNotSelected = menuProductCategoryIds.some(
        menu =>
            menu.productIds.length === 0 &&
            menu.categoryIds.length === 0 &&
            menuCheckStatus.some(status => status.id === menu.menuId && !status.markedAsRemove)
    );

    const saveButtonDisabled = preventSaving || allAreMarkedRemove || itemsNotSelected;

    return (
        <Modal open placement="center" height={"90%"} onClose={onHandleCloseModal}>
            <ModalCloseBtn onClick={onHandleCloseModal} />
            <ModalHeader>{translate("menus")}</ModalHeader>
            <ModalBody flex={"1"} overflow={"hidden"} maxH={"80vh"}>
                <Tabs paddingBottom={5} height="100%" variant="line" themeColor="teal" defaultIndex={0} isVertical>
                    <TabList flexWrap="wrap" borderLeft="none" width="200px">
                        {selectedMenus?.map((menu: DiscountMenu) => {
                            const menuStatus =
                                menuCheckStatus.find(status => status.id === menu.id)?.status ??
                                MENU_STATUS.ALL_SELECTED;
                            const menuIsEditable = menuStatus === MENU_STATUS.EDIT;
                            const isMarkedToRemove =
                                menuCheckStatus.find(status => status.id === menu.id)?.markedAsRemove ?? false;

                            return (
                                <Tab key={menu.id} fontWeight={"600"} justifyContent="start" textAlign={"left"}>
                                    {menuIsEditable && (
                                        <Box
                                            as={FaRegEdit}
                                            color="yellow.800"
                                            fontSize={"1rem"}
                                            minH={"1rem"}
                                            minW={"1rem"}
                                        />
                                    )}
                                    {isMarkedToRemove && (
                                        <Box
                                            as={MdDelete}
                                            color="red.500"
                                            fontSize={"xs"}
                                            minH={"1rem"}
                                            minW={"1rem"}
                                        />
                                    )}
                                    <Box ml={2}>{menu?.name}</Box>
                                </Tab>
                            );
                        })}
                    </TabList>
                    <TabPanels height="100%" overflowY={"auto"}>
                        {selectedMenus?.map((menu: DiscountMenu) => {
                            /** This is what the menu has under the categories */
                            const productAndCategoriesUnderMenu = menu?.menuProductCategories ?? [];
                            const { id } = menu;
                            /** This is the selected product or category ids */
                            const selectedMenuProductCategoryIds = menuProductCategoryIds?.find(
                                menu => menu.menuId === id
                            );

                            /** No categories or products then whole menu selectable! */
                            const wholeMenuSelected =
                                selectedMenuProductCategoryIds?.categoryIds.length ===
                                productAndCategoriesUnderMenu.length;

                            const statusMenu = menuCheckStatus.find(menu => menu.id === id);

                            const {
                                status: menuStatus,
                                markedAsRemove: isMarkedToRemove,
                                isFullMenu
                            } = statusMenu ?? {
                                status: MENU_STATUS.ALL_SELECTED,
                                markedAsRemove: false,
                                isFullMenu: false
                            };

                            /** If uncheck the top checkbox (all menu) then it's placed into an edit status */
                            const menuIsEditable = menuStatus === MENU_STATUS.EDIT;
                            const isTheFullMenuSelected = !isFullMenu ? false : wholeMenuSelected;
                            const isWholeMenuChecked = menuIsEditable ? false : isTheFullMenuSelected;

                            return !!productAndCategoriesUnderMenu ? (
                                <TabPanel
                                    key={id}
                                    borderLeft={"1px solid"}
                                    borderColor="teal.100"
                                    minHeight="100%"
                                    position={"relative"}
                                    p={4}
                                >
                                    <Flex
                                        position={"sticky"}
                                        top={0}
                                        zIndex={10}
                                        left={0}
                                        background={"white"}
                                        alignItems="center"
                                        width={"100%"}
                                        direction={"column"}
                                        style={{ overflowAnchor: "none" }}
                                        mb={4}
                                    >
                                        <Stack isInline stretch={2} width={"100%"} align="center">
                                            <Text m={0} p={0} fontSize="lg" fontWeight={"600"}>
                                                {menu.name}
                                            </Text>

                                            <Flex marginLeft={"auto"} alignItems={"center"}>
                                                <Flex
                                                    color={"blue.500"}
                                                    fontSize={"lg"}
                                                    alignItems={"center"}
                                                    justifyContent={"space-between"}
                                                    cursor={"pointer"}
                                                    fontWeight={"600"}
                                                    mr={4}
                                                >
                                                    <Box onClick={() => onToggleMenuStatus(id, false)}>
                                                        {translate("all")}
                                                    </Box>
                                                    <Box fontSize={"2xl"} ml={2} mr={2}>
                                                        |
                                                    </Box>
                                                    <Box onClick={() => onClearAllMenuSelection(id)}>
                                                        {translate("none")}
                                                    </Box>
                                                </Flex>

                                                {!isEditMenu && (
                                                    <IconButton
                                                        icon={!isMarkedToRemove ? MdDelete : FaUndo}
                                                        type="button"
                                                        themeColor={!isMarkedToRemove ? "red" : "teal"}
                                                        fontSize={"md"}
                                                        fontWeight={"600"}
                                                        ml={2}
                                                        size="sm"
                                                        onClick={() => setCurrentMenuStatusToRemove(id)}
                                                    />
                                                )}
                                            </Flex>
                                        </Stack>
                                        {isWholeMenuChecked && !isMarkedToRemove && (
                                            <Text
                                                m={0}
                                                p={0}
                                                color={"yellow.800"}
                                                fontSize={"sm"}
                                                justifySelf={"flex-start"}
                                                fontWeight={"600"}
                                                mr={"auto"}
                                                mt={2}
                                            >
                                                {`* ${translate("discountAppliesToAll")}`}
                                            </Text>
                                        )}

                                        <NewDivider color="teal.200" my={4} />
                                    </Flex>

                                    <MenuProductList
                                        menuId={id}
                                        menuProductCategories={productAndCategoriesUnderMenu}
                                        selectedCateogryIds={selectedMenuProductCategoryIds?.categoryIds ?? []}
                                        selectedProductIds={selectedMenuProductCategoryIds?.productIds ?? []}
                                        alcoholProductIds={alcoholProductIds}
                                        onToggleCategory={onToggleMenuCategory}
                                        onToggleProduct={onToggleMenuProduct}
                                        isDisabled={isMarkedToRemove}
                                        isWholeMenuSelected={isWholeMenuChecked}
                                    />
                                </TabPanel>
                            ) : null;
                        })}
                    </TabPanels>
                </Tabs>
            </ModalBody>
            <ModalActions marginTop={"auto"} maxHeight={"fit-content"}>
                {allAreMarkedRemove && (
                    <Text fontSize={"md"} textAlign={"center"} color={"yellow.800"} fontWeight={"600"}>
                        {`** ${translate("mustSelectAtLeastOne")}`}
                    </Text>
                )}
                <Button fullWidth themeColor="green" onClick={onHandleSave} isDisabled={saveButtonDisabled}>
                    {translate("save")}
                </Button>
            </ModalActions>
        </Modal>
    );
};
