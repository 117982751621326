import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";

import { ALL_SHOP_VALUE, Campaign } from "../types/types";
import { useQoplaStore } from "Stores/qoplaStore";
import { Box, Button, Flex, LoadingMessage, RHCheckBoxInput, RHSelectInput, SelectOption } from "Atoms";
import { CampaignWizardButtons } from "./CampaignWizardButtons";
import { useLanguage } from "LanguageProvider";
import { useModal } from "Providers";
import { modalNames } from "Constants";
import { useStepWizard } from "Organisms";
import { usePartialStepValidation } from "../hooks/usePartialStepValidation";
import { MenuCategoryProducts } from "Types";
import { confirmNotification } from "TempUtils";
import { useCampaignShopMenus } from "../hooks/useCampaignShopMenus";
import { useImperativeQuery } from "Hooks";
import { GET_ALCOHOL_PRODUCT_IDS } from "GraphQLQueries";
import { getFullMenuCategoryProductIds } from "../utils/campaignFunctions";

type Props = {
    allShops: SelectOption[];
};

export const CampaignMenuStep: React.FC<Props> = ({ allShops }) => {
    const {
        selectedCompany: { id: companyId }
    } = useQoplaStore();

    const { openModal } = useModal();
    const { isNextStep } = useStepWizard();
    const { translate } = useLanguage();

    const formContext = useFormContext<Campaign>();
    const { getValues, control, watch, setValue } = formContext;

    const selectedMenuProductIds = watch("menuCategoryAndProductIds");
    const selectAllMenus = watch("selectAllMenus");
    const selectedShopIds = watch("shopIds");
    
    const { isValid } = usePartialStepValidation<Campaign>(["selectedMenuIds"], control, true);

    const { allowedMenuDetails, companyShops } = useCampaignShopMenus({
        companyId,
        shopIds: getValues("shopIds"),
        allShops
    });

    const getAlcoholProductIds = useImperativeQuery(GET_ALCOHOL_PRODUCT_IDS);

    /** Open product selection modal */
    const onOpenModal = () => {
        const shopDetails = companyShops?.getCompanyShops ?? [];
        const selectedMenuIds = getValues("selectedMenuIds");
        const selectedMenus = allowedMenuDetails.filter(menu => selectedMenuIds?.includes(menu.id));
        openModal(modalNames.CAMPAIGN_MENU_SELECTION_MODAL, {
            menus: selectedMenus,
            shopDetails: shopDetails,
            formContext: { getValues, setValue }
        });
    };

    /**
     * Rest all the menus to the default state
     * - All menus will be selected and all products will be selected
     * - confirmation popup will be shown on selected true
     */
    const onResetMenus = async () => {
        const { value } = await confirmNotification(
            translate("selectAllMenus"),
            translate("areYouSureWantToResetTheMenus"),
            translate("yes"),
            "#38B2AC"
        );
        if (value) {
            setValue(
                "selectedMenuIds",
                allowedMenuDetails.map(menu => menu.id)
            );
            setValue("menuCategoryAndProductIds", []);
        } else {
            setValue("selectAllMenus", false);
        }
    };

    useEffect(() => {
        if (!!allowedMenuDetails.length && isNextStep) {
            formContext.setValue(
                "selectedMenuIds",
                allowedMenuDetails.map(menu => menu.id),
                { shouldValidate: true, shouldDirty: true }
            );
        }
    }, [allowedMenuDetails]);

    useEffect(() => {
        if (selectAllMenus && !!allowedMenuDetails.length) {
            onResetMenus();
        }
    }, [selectAllMenus]);

    /**
     * [FUNCTION] Pass in selected menus and check if the selected menus are in the selected shops
     * @param menusSelected
     */
    const onSetAndCheckShopIds = (menusSelected: MenuCategoryProducts[]) => {
        /** All company shops that have selected online menus */
        const allCompanyShops = companyShops?.getCompanyShops ?? [];
        const menuIds = menusSelected.map(menu => menu.menuId);

        const hasAllSelectedShops = selectedShopIds.includes(ALL_SHOP_VALUE);

        /** Filter out shop ids based on selected online menus */
        /** Need to check selected Shopids from first step*/
        const availableShopIdsFromMenus = allCompanyShops
            .filter(shop => hasAllSelectedShops || selectedShopIds.includes(shop.id))
            .filter(shop => shop.settings.onlineSettings.onlineMenuIds.some(menuId => menuIds.includes(menuId)))
            .map(shop => shop.id);

        /** Set shop ids */
        setValue("shopIds", availableShopIdsFromMenus, { shouldDirty: true, shouldValidate: true });

        if (!isNextStep) {
            /** If clicked back to this step remove user search as this can cause issues when trying to search again
             * as the user search is not valid anymore
             */
            setValue("campaignUserSearch", undefined, { shouldDirty: true, shouldValidate: true });
        }
    };

    const setFullMenuCategories = (menuId: string) => {
        const menu = allowedMenuDetails.find(menu => menu.id === menuId);
        const menuProductCategoryIds = {
            menuId: menuId,
            categoryIds: menu?.menuProductCategories?.map(cat => cat.id) ?? [],
            productIds: []
        };
        return menuProductCategoryIds;
    };

    const getFullMenuSelections = async (selectedMenuIds: string[]) => {
        const { data } = await getAlcoholProductIds({
            companyId: companyId
        });
        const alcoholProductIds = data?.getAlcoholProductIds ?? [];
        const selectedMenus = allowedMenuDetails.filter(menu => selectedMenuIds?.includes(menu.id));
        return selectedMenuIds?.map(id => {
            return getFullMenuCategoryProductIds(id, selectedMenus, new Set(alcoholProductIds));
        });
    };

    /**
     * Set the menu details if the user has not selected any products.
     * This is to make sure that the user has at least selected the menus
     * Also will check the shop ids to make sure that the selected menus are in the selected shops
     */
    const setMenuDetailsAndCheckShopIds = async () => {
        if (!selectedMenuProductIds) {
            const menuIds = getValues("selectedMenuIds");
            const fullMenuSelection = await getFullMenuSelections(menuIds ?? []);
            /** If the modal hasn't been opened then all the menu is active in the campagin discount */

            if (fullMenuSelection) {
                setValue("menuCategoryAndProductIds", fullMenuSelection);
                onSetAndCheckShopIds(fullMenuSelection);
            }
        } else {
            /** NOTE: we do this part here - someone by mistake could remove a menu and they could have choosen many products to discount and then added back the menu
             * so it isn't done in a form settings or a watch - the modal takes care of all items that are selected and then we do the final check here
             */

            /** If selected menu categories or products has been set - a few checks are needed before going onto the next step */
            const selectedMenuIds = getValues("selectedMenuIds");
            /** Step 1: Make sure all are in there that are in the selected menus id */
            const updatedMenuProductCategoryIds = selectedMenuProductIds.filter(menu =>
                selectedMenuIds?.includes(menu.menuId)
            );
            /** Step 2: Are there more selected menus in the select if so further step needed */
            const hasMoreMenusSelected = (selectedMenuIds?.length ?? 0) > updatedMenuProductCategoryIds.length;
            if (hasMoreMenusSelected) {
                /** Step 3: Get the menus that are no in the menuCategoryAndProductIds */
                const menuIdsInSelected = selectedMenuIds?.filter(
                    menuId => !updatedMenuProductCategoryIds.some(menu => menu.menuId === menuId)
                );

                /** Step 4: For each menu not in the menuCategoryAndProductIds create a new empty one for that menu */
                const updatedMenuProductCategoryIdsWithMissingMenus = await getFullMenuSelections(
                    menuIdsInSelected ?? []
                );

                const selectedMenuProductIdsWithCategories = [
                    ...(updatedMenuProductCategoryIdsWithMissingMenus ?? []),
                    ...updatedMenuProductCategoryIds
                ];

                setValue("menuCategoryAndProductIds", selectedMenuProductIdsWithCategories);
                onSetAndCheckShopIds(selectedMenuProductIdsWithCategories);
            } else {
                const fullMenuCategories = updatedMenuProductCategoryIds?.map(category => {
                    const isFullMenu = category.categoryIds.length === 0 && category.productIds.length === 0;
                    return isFullMenu ? setFullMenuCategories(category.menuId) : category;
                });

                setValue("menuCategoryAndProductIds", fullMenuCategories);
                onSetAndCheckShopIds(fullMenuCategories);
            }
        }
    };

    const menuSelectOptions = allowedMenuDetails?.map(menu => ({
        label: menu.name,
        value: menu.id
    }));

    if (!allowedMenuDetails?.length && !companyShops?.getCompanyShops) {
        return <LoadingMessage />;
    }

    return (
        <>
            <RHCheckBoxInput control={control} name="selectAllMenus">
                {translate("selectAllMenus")}

                <Box as="span" fontSize={"xs"} color="yellow.800" ml={4}>
                    {`* ${translate("allProductsWillBeSelected")}`}
                </Box>
            </RHCheckBoxInput>
            <Flex direction={"column"} minH="200px" maxH={"300px"} overflow={"auto"}>
                <RHSelectInput
                    control={control}
                    name="selectedMenuIds"
                    formLabel={translate("associatedMenus")}
                    options={menuSelectOptions}
                    isMulti
                    isMandatory
                    isClearable={menuSelectOptions.length > 1}
                    isDisabled={selectAllMenus}
                />
            </Flex>
            <Flex mt={4} alignItems={"center"} justifyContent={"center"}>
                <Button
                    fullWidth
                    borderRadius={"xxl"}
                    themeColor="teal"
                    boxShadow={"md"}
                    size="md"
                    width={"50%"}
                    onClick={onOpenModal}
                    isDisabled={!isValid || selectAllMenus}
                >
                    {translate("chooseProducts")}
                </Button>
            </Flex>

            <CampaignWizardButtons
                wrapperProps={{ mt: 8 }}
                isValid={isValid}
                onNextClick={setMenuDetailsAndCheckShopIds}
            />
        </>
    );
};
