import { useEffect, useState } from "react";

import { useImperativeQuery, useMothershipQuery } from "Hooks";
import { ALL_SHOP_VALUE, CampaignQuery } from "../types/types";
import { GET_ALL_COMPANY_MENUS_FOR_CAMPAIGN, GET_ALL_COMPANY_SHOPS_FOR_CAMPAIGN } from "GraphQLQueries";
import { DiscountMenu, EatingOption } from "Types";
import { SelectOption } from "Atoms";

type ShopMenuHookReturn = {
    allowedMenuDetails: DiscountMenu[];
    companyShops?: CampaignQuery.getCompanyShops;
    eatingOptions: EatingOption[];
    setEatingOptionsBasedOnShopsAndMenus: (selectedShopIds: string[]) => void;
};

type ShopMenuHookProps = {
    companyId: string;
    shopIds: string[];
    allShops?: SelectOption[];
};

/**
 * [HOOK] - collecting menus by company id and menu ids
 * - Moved this part into a hook to make it reusable for editing campaigns for support!
 * @param param0
 * @returns
 */
export const useCampaignShopMenus = ({ companyId, shopIds, allShops }: ShopMenuHookProps): ShopMenuHookReturn => {
    const [allMenuDetails, setAllMenuDetails] = useState<DiscountMenu[]>([]);
    const [allowedMenuDetails, setAllowedMenuDetails] = useState<DiscountMenu[]>([]);
    const [eatingOptions, setEatingOptions] = useState<EatingOption[]>([]);

    const { data: companyShops, loading: companyShopsAreLoading } = useMothershipQuery<CampaignQuery.getCompanyShops>(
        GET_ALL_COMPANY_SHOPS_FOR_CAMPAIGN,
        {
            variables: { companyId: companyId }
        }
    );

    const getMenusForCampaign = useImperativeQuery(GET_ALL_COMPANY_MENUS_FOR_CAMPAIGN);

    const onHandleMenuSelection = async () => {
        const allCompanyShops = companyShops?.getCompanyShops ?? [];

        const selectedShopIds =
            shopIds.includes(ALL_SHOP_VALUE) && !!allShops ? allShops?.map(shop => shop.value) : shopIds;

        /** By shop ids selected in step 1 - get allowed online menus for this selection */
        const allowedMenus = allCompanyShops
            .filter(shop => selectedShopIds.includes(shop.id))
            .map(shop => shop.settings.onlineSettings.onlineMenuIds)
            .flat();

        const { data: selectedMenus } = await getMenusForCampaign({ onlineMenuIds: allowedMenus });

        if (!!selectedMenus?.getSelectedOnlineMenusForCampaign) {
            const menusToSet = selectedMenus.getSelectedOnlineMenusForCampaign;
            setAllMenuDetails(menusToSet);
            setAllowedMenuDetails(menusToSet);
            setEatingOptions([
                ...new Set<EatingOption>(
                    menusToSet.flatMap((menu: { eatingOptions: EatingOption[] }) => menu.eatingOptions)
                )
            ]);
        }
    };

    /** [FUTURE_FUNCTION]
     * - This function will be used to set eating options based on selected shops and menus
     * - We decided not to do this at present - but to keep it here for future reference
     */
    const setEatingOptionsBasedOnShopsAndMenus = (selectedShopIds: string[]) => {
        const allCompanyShops = companyShops?.getCompanyShops ?? [];
        const shopIdsToUse =
            selectedShopIds.includes(ALL_SHOP_VALUE) && !!allShops
                ? allShops?.map(shop => shop.value)
                : selectedShopIds;

        const allowedMenus = allCompanyShops
            .filter(shop => shopIdsToUse.includes(shop.id))
            .map(shop => shop.settings.onlineSettings.onlineMenuIds)
            .flat();

        const menusToSet = allMenuDetails.filter(menu => allowedMenus.includes(menu.id));
        const menuEatingOptions = menusToSet.flatMap((menu: { eatingOptions: EatingOption[] }) => menu.eatingOptions);

        setEatingOptions([...new Set(menuEatingOptions)]);
    };

    useEffect(() => {
        if (!companyShopsAreLoading && !!companyShops) {
            onHandleMenuSelection();
        }
    }, [companyShopsAreLoading]);

    return {
        allowedMenuDetails,
        companyShops,
        eatingOptions,
        setEatingOptionsBasedOnShopsAndMenus
    };
};
