import React from "react";
import { TiTick } from "@react-icons/all-files/ti/TiTick";

import { Box, Flex, FlexProps, BaseBoxProps, MotionDiv } from "Atoms";
import { useLanguage } from "LanguageProvider";
import { useStepWizard, ValidStep } from "Organisms";
import { Stepper } from "../types/types";

const ProgressStep = ({ ...props }: FlexProps) => (
    <MotionDiv
        initial="initialState"
        animate="animateState"
        exit="exitState"
        //@ts-ignore
        transition={{ duration: 0.7 }}
        variants={{
            initialState: { scale: 0, borderWidth: 0, borderColor: "white" },
            animateState: { scale: 1, borderWidth: "3px", borderColor: "#38B2AC" },
            exitState: { scale: 0 }
        }}
        display="flex"
        w="35px"
        h="35px"
        borderRadius="50%"
        borderStyle="solid"
        color="gray.900"
        fontStyle="bold"
        mb={4}
        justifyContent="center"
        alignItems="center"
        {...props}
    />
);

const ProgressLine = ({ ...props }: BaseBoxProps) => (
    <Flex direction="column" alignItems="center">
        <Box height="2px" backgroundColor="gray.400" width={["10px", "10px", "45px"]} mb="2.5rem" {...props} />
    </Flex>
);

const CompletedProgressLine = () => (
    <Flex alignItems="center">
        <Box height="2px" backgroundColor="teal.500" width={["5px", "5px", "10px"]} mb="2.5rem" />
        <MotionDiv
            initial="initialState"
            animate="animateState"
            exit="exitState"
            //@ts-ignore
            transition={{ duration: 0.7 }}
            variants={{ initialState: { scale: 0 }, animateState: { scale: 1 }, exitState: { scale: 0 } }}
            w="10px"
            h="10px"
            borderRadius="50%"
            backgroundColor="teal.500"
            mb="2.5rem"
            display={["none", "none", "inline-block"]}
        />
        <Box
            height="2px"
            backgroundColor="teal.500"
            width={["5px", "5px", "10px"]}
            mb="2.5rem"
            display={["none", "none", "inline-block"]}
        />
    </Flex>
);

const ProgressStepWrapper = ({ ...props }: FlexProps) => (
    <Flex direction="column" alignItems="center" width="100%" {...props} />
);

const CompletedProgressStep = () => (
    <ProgressStep backgroundColor="teal.500">
        <Box color="white" fontSize="xl" as={TiTick} />
    </ProgressStep>
);

export const CampaignProgress: React.FC<{}> = ({}) => {
    const { totalSteps, currentStep, stepsToValidate } = useStepWizard();

    const { translate } = useLanguage();

   const stepper: Stepper[] = [
       { step: 1, title: translate("details") },
       { step: 2, title: translate("discount") },
       { step: 3, title: translate("menus") },
       { step: 4, title: translate("customers") },
       { step: 5, title: translate("summary") }
   ];

    const currentStepStyle: FlexProps = { color: "gray.900", borderWidth: "3px" };

    return (
        <Flex justifyContent="center" alignItems="center" p={4} maxH="100px" mb={[4, 0, 0]}>
            {stepper.map(value => {
                const isCurrentStep = value.step === currentStep;
                const lastStep = value.step === totalSteps;
                const currentStepIsValid =
                    stepsToValidate?.some((stepValue: ValidStep) => stepValue.step === value.step && stepValue.valid) ??
                    false;
                const passedStep = value.step < (currentStep ?? 0);
                return (
                    <React.Fragment key={value.step}>
                        <ProgressStepWrapper>
                            {passedStep || currentStepIsValid ? (
                                <CompletedProgressStep />
                            ) : (
                                <ProgressStep {...(isCurrentStep && { ...currentStepStyle })}>
                                    {value.step}
                                </ProgressStep>
                            )}

                            <Box fontSize="sm" fontWeight="bold">
                                {value.title}
                            </Box>
                        </ProgressStepWrapper>
                        {!lastStep ? passedStep ? <CompletedProgressLine /> : <ProgressLine /> : null}
                    </React.Fragment>
                );
            })}
        </Flex>
    );
};
