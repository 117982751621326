import { roundToTwo } from "NumberUtils";
import {
    MAX_COST_CAP,
    UserCount,
    TotalUserCountAndCost,
    COST_PER_EMAIL,
    MIN_CAMPAIGN_COST,
    Campaign,
    NEXT_CAMPAIGN_IN_DAYS,
    QoplaPromotion
} from "../types/types";
import { isTodayAfterStartDate, isTodayBeforeEndDate, setEndOfDay, setStartOfDay } from "Utils";
import { DiscountMenu, MenuCategoryProducts } from "Types";

/**
 * [FUNCTION] - get max total cost cap
 * @param numberOfShops
 * @returns
 */
export const getTotalMaxCostCap = (numberOfShops: number) => {
    if (numberOfShops >= 1 && numberOfShops <= 4) {
        return MAX_COST_CAP.PER_SHOP;
    } else if (numberOfShops >= 5) {
        return MAX_COST_CAP.PER_COMPANY;
    }
    return 0;
};

/**
 * [FUNCTION] - adds up all users from all shops inc max customers per shop
 * @param potentialUsers
 * @returns
 */
export const getTotalNumberOfUsers = (potentialUsers: UserCount[], maxNumberOfCustomersPerShop: number = 0) => {
    return potentialUsers.reduce(
        (total, user) =>
            (total +=
                maxNumberOfCustomersPerShop > 0
                    ? Math.min(user.numberOfPotentialUsers, maxNumberOfCustomersPerShop)
                    : user.numberOfPotentialUsers),
        0
    );
};

/**
 * [FUNCTION] -  calculating all costs for campigan
 * @param numberOfShops
 * @param numberOfPotentialUsers
 * @param isCostPerShop
 * @param maxCostCap
 * @returns
 */
export const calculateEmailCost = (
    numberOfShops: number,
    numberOfPotentialUsers: number,
    isCostPerShop: boolean,
    maxCostCap: number
): TotalUserCountAndCost => {
    if (isCostPerShop) {
        const totalCappedCost = numberOfShops * maxCostCap;
        const totalPotentialCost = numberOfPotentialUsers * COST_PER_EMAIL;
        const totalCost = Math.min(totalPotentialCost, totalCappedCost);
        const minTotalCost = Math.max(MIN_CAMPAIGN_COST, totalCost);
        const costPerUser = roundToTwo(totalCost / numberOfPotentialUsers);

        return {
            numberOfShops,
            numberOfPotentialUsers,
            isCostPerShop,
            maxCostCap,
            totalCost: minTotalCost,
            costPerUser
        };
    } else {
        const totalPotentialCost = numberOfPotentialUsers * COST_PER_EMAIL;
        const totalCost = Math.min(totalPotentialCost, maxCostCap);
        const minTotalCost = Math.max(MIN_CAMPAIGN_COST, totalCost);
        const costPerUser = roundToTwo(totalCost / numberOfPotentialUsers);

        return {
            numberOfShops,
            numberOfPotentialUsers,
            isCostPerShop,
            maxCostCap,
            totalCost: minTotalCost,
            costPerUser
        };
    }
};

/**
 * [FUNCTION] - get total user count and max per shop or company
 * @param potentialUserCount
 * @returns
 */
export const getTotalUserCountAndCost = (
    potentialUserCount: UserCount[],
    maxNumberOfCustomersPerShop: number = 0
): TotalUserCountAndCost => {
    const filterOutZeroCustomers = potentialUserCount.filter(user => user.numberOfPotentialUsers > 0);
    if (!!filterOutZeroCustomers.length) {
        const numberOfShops = filterOutZeroCustomers.length;
        const maxCostCap = getTotalMaxCostCap(numberOfShops);
        const numberOfPotentialUsers = getTotalNumberOfUsers(filterOutZeroCustomers, maxNumberOfCustomersPerShop);
        return calculateEmailCost(numberOfShops, numberOfPotentialUsers, numberOfShops <= 4, maxCostCap);
    }
    return {} as TotalUserCountAndCost;
};

/**
 * [FUNCTION] Pass latest campaign and find the start of the next possible campaign by const in the types
 * @param latestCampaign
 * @param numberOfDays
 * @returns
 */
export const getNextCampaignStartDate = (latestCampaign: Campaign, numberOfDays: number = NEXT_CAMPAIGN_IN_DAYS) => {
    const nextCampaignStartDate = new Date(latestCampaign.startDate);
    nextCampaignStartDate.setHours(0, 0, 0, 0);
    nextCampaignStartDate.setDate(nextCampaignStartDate.getDate() + numberOfDays);
    return nextCampaignStartDate;
};

/**
 * [FUNCTION] - get the first previous valid campaign - as it could have been ended before it started so we want to ignore these
 * @param campaigns
 * @returns
 */
export const getPreviousValidCampaign = (campaigns: Campaign[]) => {
    return campaigns.find(val => new Date(val.endDate).getTime() > new Date(val.startDate).getTime());
};

/**
 * [FUNCTION] - check if promotion can be used
 * @param promotion
 * @param hasAlreadyUsedPromotion
 * @returns
 */
export const canUsePromotion = (promotion: QoplaPromotion, hasAlreadyUsedPromotion: boolean) => {
    const { startDate, endDate } = promotion;
    const startOfDay = setStartOfDay(new Date(startDate));
    const endOfDay = setEndOfDay(new Date(endDate));
    const canUsePromotion = isTodayAfterStartDate(startOfDay) && isTodayBeforeEndDate(endOfDay);
    return canUsePromotion && !hasAlreadyUsedPromotion;
};

/**
 * [FUNCTION] - get all product ids from a menu
 * @param menuId
 * @param discountMenus
 * @param exludedProductIds
 * @returns
 */
export const getFullMenuCategoryProductIds = (
    menuId: string,
    discountMenus: DiscountMenu[],
    exludedProductIds: Set<string>
): MenuCategoryProducts => {
    const menu = discountMenus.find(menu => menu.id === menuId);
    let categoryIds = [] as string[];
    let productIds = [] as string[];
    menu?.menuProductCategories.forEach(category => {
        /** Select all products in category */
        const allProducts = [...(category?.menuBundleProducts ?? []), ...(category?.menuProducts ?? [])];
        /** Get all ids under the category */
        const allProductIds = allProducts.map(prod => prod.id);
        /** Check against excluded ids */
        const productsIdsWithoutExcluded = allProducts
            .filter(
                product =>
                    !exludedProductIds.has(
                        "refProduct" in product ? product?.refProduct.id : product?.refBundleProduct.id
                    )
            )
            .map(prod => prod.id);
        
        /** Check all product ids length against the product id against the excluded (also excluded) */
        const hasAllProducts = allProductIds.length === productsIdsWithoutExcluded.length && productsIdsWithoutExcluded.length > 0;

        if (hasAllProducts) {
            /** If it has all products add the category id */
            categoryIds = [...categoryIds, category.id];
        } else {
            /** If the category doesn't have all the ids add the product ids */
            productIds = [...productIds, ...productsIdsWithoutExcluded];
        }
    });

    return {
        menuId,
        categoryIds,
        productIds
    };
};

/**
 * [FUNCTION] - check if the whole menu is selected
 * @param menuId
 * @param menus
 * @param categoryIds
 * @returns
 */
export const isFullMenuSelected = (menuId: string, menus: DiscountMenu[], categoryIds: string[]) => {
    const menu = menus.find(menu => menu.id === menuId);
    const allCategoryIds = menu?.menuProductCategories.map(cat => cat.id);
    return allCategoryIds?.length === categoryIds.length;
};
