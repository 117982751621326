import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import moment, { Moment } from "moment";

import { ALL_SHOP_VALUE, Campaign, CampaignQuery, SetEmailPreviewFunction } from "../types/types";
import { Button, Flex, RHDatePickerInput, RHFormInput, RHSelectInput, RHTextAreaInput, SelectOption } from "Atoms";
import { useLanguage } from "LanguageProvider";
import { CountryLocale, EatingOption } from "Types";
import { usePartialStepValidation } from "../hooks/usePartialStepValidation";
import { getMinimumEndDateFromStartDate } from "Utils";
import { CampaignWizardButtons } from "./CampaignWizardButtons";
import { useStepWizard } from "Organisms";
import { useQoplaStore } from "Stores";
import { useMothershipQuery } from "Hooks";
import { GET_COMPANY_SHOPS_EATING_OPTIONS } from "GraphQLQueries";

type Props = {
    countryLocale: CountryLocale;
    allShops: SelectOption[];
    setEmailPreview: SetEmailPreviewFunction;
    onCancelCampaign: () => Promise<void>;
};

export const CampaignDetailsStep: React.FC<Props> = ({
    countryLocale,
    allShops,
    setEmailPreview,
    onCancelCampaign
}) => {
    const [hideEatingOptions, setHideEatingOptions] = useState<boolean>(false);
    const { translate, translateWithArgument } = useLanguage();
    const {
        selectedCompany: { id: companyId }
    } = useQoplaStore();
    /** In the wizard there is nextStep going forward (even on first page is next step) - if you click back it is set to false */
    const { isNextStep } = useStepWizard();
    const { control, setValue, watch } = useFormContext<Campaign>();

    const { data, loading } = useMothershipQuery<CampaignQuery.getCompanyEatingOptions>(
        GET_COMPANY_SHOPS_EATING_OPTIONS,
        {
            variables: { companyId }
        }
    );

    /** This step needs to be validated before moving on */
    const { isValid } = usePartialStepValidation<Campaign>(["name", "shopIds", "eatingOptions"], control, true);

    /** Locale code for date picker */
    const datePickerLocaleCode = countryLocale.localeCode.toLowerCase();

    const name = watch("name");
    const shopIds = watch("shopIds");
    const startDate = watch("startDate");
    const endDate = watch("endDate");
    const campaignMessage = watch("campaignMessage");

    /** MVP 2 */
    /*  const themeOptions = Object.keys(CAMPAIGN_THEME).map((value: string) => ({
        value,
        label: translate(value as Languagekey)
    }));
 */
    const minimumEndDate = getMinimumEndDateFromStartDate(startDate?.toString());

    /**  */
    const eatingOptionsForCampaign: SelectOption[] = [
        { value: EatingOption.EAT_HERE, label: translate(EatingOption.EAT_HERE) },
        { value: EatingOption.TAKE_AWAY, label: translate(EatingOption.TAKE_AWAY) },
        { value: EatingOption.HOME_DELIVERY, label: translate(EatingOption.HOME_DELIVERY) },
        { value: EatingOption.CATERING, label: translate(EatingOption.CATERING) }
    ];

    /** If min end date changes then check & possible change of end date */
    useEffect(() => {
        if (!!endDate && !!startDate) {
            if (moment(endDate).isBefore(startDate)) {
                setValue("endDate", minimumEndDate.toDate());
            }

            // Thanks to the default dates had to add this to make sure it didn't go further than a month
            if (moment(endDate).diff(moment(startDate), "days") > 31) {
                setValue("endDate", moment(startDate).add(1, "month").toDate());
            }
        }
    }, [minimumEndDate]);

    useEffect(() => {
        setEmailPreview("name", name);
    }, [name]);

    useEffect(() => {
        setEmailPreview(
            "campaignMessage",
            campaignMessage?.trim() ? campaignMessage : translateWithArgument("campaignText", "X")
        );
    }, [campaignMessage]);

    useEffect(() => {
        if (shopIds?.length > 1 && shopIds?.includes(ALL_SHOP_VALUE)) {
            /** If someone add "All Restaurants" remove all other ids */
            setValue("shopIds", [ALL_SHOP_VALUE], { shouldDirty: true, shouldValidate: true });
        }
        if (shopIds?.length === allShops.length && allShops.length > 3) {
            /** If all shops are added then swap for "All Resturants" */
            setValue("shopIds", [ALL_SHOP_VALUE], { shouldDirty: true, shouldValidate: true });
        }

        if (!isNextStep) {
            /** If clicked back to this step remove user search as this can cause issues when trying to search again
             * as the user search is not valid anymore
             */
            setValue("campaignUserSearch", undefined, { shouldDirty: true, shouldValidate: true });
        }
    }, [shopIds]);

    useEffect(() => {
        if (allShops?.length > 3 && isNextStep) {
            /** Add "All Restaurants" when entering the campaign tool (if number of shops is greater than 3) */
            setValue("shopIds", [ALL_SHOP_VALUE], { shouldDirty: true, shouldValidate: true });
        }
        if (allShops?.length === 1) {
            setValue("shopIds", [allShops[0].value], { shouldDirty: true, shouldValidate: true });
        }
    }, [allShops?.length >= 1]);

    useEffect(() => {
        if (!!data?.getAllEatingOptions && !loading && isNextStep) {
            /** Eating options from all company shops (in a set) */
            const eatingOptions = [...data.getAllEatingOptions];
            /** Hide eating options if is only 2 choices and are eat here or take away */
            const hidePickUpSelection =
                eatingOptions?.length === 2 &&
                eatingOptions?.includes(EatingOption.EAT_HERE) &&
                eatingOptions?.includes(EatingOption.TAKE_AWAY);
            setHideEatingOptions(hidePickUpSelection);
            setValue("eatingOptions", eatingOptions, { shouldDirty: true, shouldValidate: true });
        }
    }, [data]);

    const allShopSelect: SelectOption = { label: translate("allRestaurants"), value: ALL_SHOP_VALUE };
    const hasAlotOfShops = allShops.length > 3;
    const shopOptions = hasAlotOfShops ? [allShopSelect, ...allShops] : allShops;

    const updatedOptions = shopIds?.includes(ALL_SHOP_VALUE)
        ? shopOptions.map(option => ({ ...option, isDisabled: option.value !== ALL_SHOP_VALUE }))
        : shopOptions;

    /** Hide if only one shop */
    const hideShopSelection = allShops?.length === 1;

    return (
        <>
            <RHFormInput
                control={control}
                name="name"
                formLabel={translate("campaignName")}
                isMandatory
                tooltipLabelText={translate("campaignExample")}
                showExtendedLabelText
            />
            <RHTextAreaInput
                placeholder={translateWithArgument("campaignText", "X")}
                control={control}
                name="campaignMessage"
                formLabel={translate("campaignDescription")}
                maxLength={200}
                tooltipLabelText={translateWithArgument("maxXCharacters", 200)}
                showExtendedLabelText
            />
            {!hideShopSelection ? (
                <RHSelectInput
                    control={control}
                    name="shopIds"
                    formLabel={translate("restaurants")}
                    options={updatedOptions}
                    isMandatory
                    isMulti
                    isClearable={allShops.length > 1}
                />
            ) : null}
            {!hideEatingOptions && (
                <RHSelectInput
                    formLabel={translate("pickUpSelection")}
                    placeholder={translate("pickUpSelection")}
                    noOptionsMessage={() => translate("noAlternative")}
                    name="eatingOptions"
                    options={eatingOptionsForCampaign}
                    isMulti
                    isClearable
                    control={control}
                />
            )}

            <Flex width="100%">
                <RHDatePickerInput
                    control={control}
                    name="startDate"
                    formLabel={translate("startDate")}
                    dateFormat="YYYY-MM-DD"
                    locale={datePickerLocaleCode}
                    minDate={moment().add(1, "day")}
                    maxDate={moment().add(15, "day")}
                    autoComplete={"off"}
                    transformBeforeOnChange={(moment: Moment) => {
                        return moment.toDate();
                    }}
                    isMandatory
                    isFullWidth
                    wrapperProps={{ width: "100%", marginRight: 2 }}
                />
                <RHDatePickerInput
                    control={control}
                    name="endDate"
                    formLabel={translate("endDate")}
                    dateFormat="YYYY-MM-DD"
                    locale={datePickerLocaleCode}
                    minDate={minimumEndDate.add(1, "day")}
                    maxDate={moment(startDate).add(1, "month")}
                    autoComplete={"off"}
                    transformBeforeOnChange={(moment: Moment) => {
                        return moment.toDate();
                    }}
                    isMandatory
                    isFullWidth
                    wrapperProps={{ width: "100%", marginLeft: 2 }}
                />
            </Flex>
            {/** NOT MVP 1 */}
            {/*             <RHSelectInput
                control={control}
                name="theme"
                formLabel={translate("theme")}
                options={themeOptions}
                isMandatory
            /> */}
            <CampaignWizardButtons isValid={isValid}>
                <Button variant="outline" themeColor="red" size="lg" onClick={onCancelCampaign}>
                    {translate("cancel")}
                </Button>
            </CampaignWizardButtons>
        </>
    );
};
