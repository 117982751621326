import React from "react";
import { FaChevronDown } from "@react-icons/all-files/fa/FaChevronDown";
import { FaChevronUp } from "@react-icons/all-files/fa/FaChevronUp";

import { Box, Checkbox, Flex, Stack, Text, IconButton } from "Atoms";
import { CHECKOUT_DISCOUNT_VALIDATIONS, MenuProductCategories } from "Types";
import { useSwitch } from "Hooks";
import { useLanguage } from "LanguageProvider";

type Props = {
    menuId: string;
    menuProductCategories: MenuProductCategories[];
    selectedProductIds: string[];
    selectedCateogryIds: string[];
    onToggleCategory: (categoryId: string, isChecked: boolean, menuId: string) => void;
    onToggleProduct: (categoryId: string, productId: string, isChecked: boolean, menuId: string) => void;
    isDisabled: boolean;
    isWholeMenuSelected: boolean;
    alcoholProductIds?: string[] | null;
};

export const MenuProductList: React.FC<Props> = ({
    menuProductCategories,
    alcoholProductIds,
    selectedCateogryIds,
    selectedProductIds,
    menuId,
    isDisabled,
    isWholeMenuSelected,
    onToggleCategory,
    onToggleProduct
}) => {
    const { translate } = useLanguage();
    const hasAlcoholProducts = !!alcoholProductIds?.length;
    return (
        <Stack stretch={8}>
            {isDisabled && (
                <Text
                    fontSize={"md"}
                    color={"yellow.800"}
                    fontWeight={"600"}
                >{`** ${translate("theMenuIsNotActiveForCampaign")}`}</Text>
            )}
            {!isDisabled &&
                menuProductCategories.map(category => {
                    const { open, onToggle } = useSwitch();
                    const icon = open ? FaChevronUp : FaChevronDown;
                    const menuProducts = [...category.menuProducts, ...category.menuBundleProducts];
                    /** Category has been selected */
                    const categoryIsSelected = selectedCateogryIds.includes(category.id);
                    /** Category contains non discounted products */
                    const productsHaveNonDiscountedItems = menuProducts?.some(menuProduct => {
                        const product =
                            "refProduct" in menuProduct ? menuProduct.refProduct : menuProduct.refBundleProduct;
                        return alcoholProductIds?.includes(product.id);
                    });

                    /** Category is disabled */
                    const categoryIsDisabled = hasAlcoholProducts && productsHaveNonDiscountedItems;

                    const haveProductsBeenChecked =
                        [...category.menuProducts, ...category.menuBundleProducts].some(menu =>
                            selectedProductIds.includes(menu.id)
                        ) ?? false;

                    const someProductsAreChecked = haveProductsBeenChecked && !categoryIsSelected;
                    const disableCategoryCheckbox = categoryIsDisabled || isDisabled;

                    return (
                        <Box key={category.id}>
                            <Stack isInline stretch={2} align="center">
                                <IconButton
                                    icon={icon}
                                    type="button"
                                    themeColor="teal"
                                    fontWeight={"600"}
                                    variant="outline"
                                    size="sm"
                                    onClick={onToggle}
                                />
                                <Checkbox
                                    onChange={() =>
                                        onToggleCategory(category.id, categoryIsSelected, menuId)
                                    }
                                    themeColor="teal"
                                    checked={categoryIsSelected || isWholeMenuSelected}
                                    isDisabled={disableCategoryCheckbox}
                                />
                                <Flex>
                                    <Text fontSize="lg">
                                        {category.name}
                                        {someProductsAreChecked && (
                                            <Box as="span" fontWeight={"600"} fontSize={"xs"} ml={2} color="teal.500">
                                                {`* ${translate("selectedProducts")}`}
                                            </Box>
                                        )}
                                        {!someProductsAreChecked && productsHaveNonDiscountedItems && (
                                            <Box as="span" fontWeight={"600"} fontSize={"xs"} ml={2} color="yellow.800">
                                                {`* ${translate("containsAlcoholDrinks")}`}
                                            </Box>
                                        )}
                                    </Text>
                                </Flex>
                            </Stack>
                            {open && (
                                <Stack mx={12} mt={4}>
                                    {menuProducts.map((menuProduct, index) => {
                                        const product =
                                            "refProduct" in menuProduct
                                                ? menuProduct.refProduct
                                                : menuProduct.refBundleProduct;

                                        const isProductChecked = selectedProductIds.includes(menuProduct.id);
                                        const refId = product.id;
                                        const isAlcoholProduct = !hasAlcoholProducts
                                            ? false
                                            : alcoholProductIds.includes(refId);
                                        const productCheckboxIsDisabled =
                                            isAlcoholProduct || categoryIsSelected || isDisabled || isWholeMenuSelected;

                                        return (
                                            <Flex key={index} alignItems={"center"}>
                                                <Checkbox
                                                    disabled={productCheckboxIsDisabled}
                                                    onChange={() =>
                                                        !isWholeMenuSelected &&
                                                        onToggleProduct(
                                                            category.id,
                                                            menuProduct.id,
                                                            isProductChecked,
                                                            menuId
                                                        )
                                                    }
                                                    themeColor="teal"
                                                    checked={
                                                        isProductChecked || (!isAlcoholProduct && isWholeMenuSelected)
                                                    }
                                                >
                                                    {product.name}
                                                </Checkbox>

                                                {isAlcoholProduct && (
                                                    <Box
                                                        as="span"
                                                        fontWeight={"600"}
                                                        fontSize={"xs"}
                                                        ml={4}
                                                        color={"yellow.800"}
                                                    >
                                                        {`* ${translate(CHECKOUT_DISCOUNT_VALIDATIONS.NO_DISCOUNT_ON_ALCOHOL)}`}
                                                    </Box>
                                                )}
                                            </Flex>
                                        );
                                    })}
                                </Stack>
                            )}
                        </Box>
                    );
                })}
        </Stack>
    );
};
