import React, { PropsWithChildren } from "react";

import { Button, Flex, FlexProps } from "Atoms";
import { useStepWizard, AnimationDirection } from "Organisms";
import { useLanguage } from "LanguageProvider";

type WizardButtons = {
    isValid: boolean;
    wrapperProps?: FlexProps;
    onPreviousClick?: () => void;
    onNextClick?: () => void;
};

export const CampaignWizardButtons: React.FC<PropsWithChildren<WizardButtons>> = ({
    isValid = false,
    wrapperProps,
    onPreviousClick,
    onNextClick,
    children
}) => {
    const { setStepAndAnimation, currentStep } = useStepWizard();
    const { translate } = useLanguage();

    const onHandleStepDirection = (animationDirection: AnimationDirection) => {
        const isNextStep = animationDirection === "FORWARD";
        if (currentStep) {
            if (isNextStep && !!onNextClick) {
                onNextClick();
            }

            if (!isNextStep && !!onPreviousClick) {
                onPreviousClick();
            }

            setStepAndAnimation(currentStep, animationDirection);
        }
    };

    return (
        <Flex alignSelf="flex-end" justifyContent="space-between" {...wrapperProps}>
            {!!children ? (
                <>{children}</>
            ) : (
                <Button variant="outline" size="lg" onClick={() => onHandleStepDirection("BACK")}>
                    {translate("back")}
                </Button>
            )}

            <Button
                backgroundColor="newPrimary"
                color="white"
                size="lg"
                _hover={{ backgroundColor: "primaryAlpha.800" }}
                isDisabled={!isValid}
                onClick={() => onHandleStepDirection("FORWARD")}
            >
                {translate("next")}
            </Button>
        </Flex>
    );
};
