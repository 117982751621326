import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

import {
    Campaign,
    CAMPAIGN_DISCOUNT_TYPE,
    getDiscountAmountSelectOptions,
    discountRateSelectOptions,
    maxRedeemedSelectOptions,
    SetEmailPreviewFunction
} from "../types/types";
import { RHFormInput, RHCheckBoxInput, RHSelectInput, Text, RHTextAreaInput, NewGrid as Grid, } from "Atoms";
import { useLanguage } from "LanguageProvider";
import { usePartialStepValidation } from "../hooks/usePartialStepValidation";
import { CampaignWizardButtons } from "./CampaignWizardButtons";
import { useQoplaStore } from "Stores";

export const CampaignDiscountStep: React.FC<{ setEmailPreview: SetEmailPreviewFunction }> = ({ setEmailPreview }) => {
    const { translate } = useLanguage();

    const { control, trigger, watch, setValue, getValues, setError, clearErrors} = useFormContext<Campaign>();

    /** Watch discount type - for valdiation don't need a code for individual as this will be generated in the backend */
    const [discountType, campaignCode, emailOnlyDiscount, offerToNewCustomers, minimumSpend] = watch([
        "discountType",
        "campaignCode",
        "emailOnlyDiscount",
        "offerToNewCustomers",
        "minimumSpend"
    ]);
    const { companyLocale } = useQoplaStore();

    const discountRate = String(watch("discountRate") ?? null);
    const discountAmount = String(watch("discountAmount") ?? null); 

    /** Partial validation ready for next step */
    const { isValid } = usePartialStepValidation<Campaign>(["campaignCode"], control, true);

    const [isStepValid, setIsStepValid] = useState<boolean>(false);

    useEffect(() => {
        const checkValidity = () => {
            const isDiscountRateValid = discountRate !== "0";
            const isDiscountAmountValid = discountAmount !== "0";
            const isMinimumSpendValid =
                discountAmount === "0" || (!!minimumSpend && Number(minimumSpend) >= Number(discountAmount) * 2);
            setIsStepValid((isDiscountRateValid || (isDiscountAmountValid && isMinimumSpendValid)) );
        };
        checkValidity();
    }, [discountRate, discountAmount, minimumSpend]);


    /** Trigger validation on campiagn code / Group needs a code
     * Individual is for MVP 2
     */
    useEffect(() => {
        if (discountType === CAMPAIGN_DISCOUNT_TYPE.INDIVIDUAL) {
            setValue("campaignCode", undefined, { shouldValidate: true, shouldDirty: false });
        } else {
            trigger("campaignCode");
        }
    }, [discountType]);

    useEffect(() => {
        setEmailPreview("discountCode", campaignCode ?? "");
        setEmailPreview("discountRate", discountRate ?? 0);
        setEmailPreview("discountAmount", discountAmount ?? 0);
        setEmailPreview("minimumSpend", minimumSpend ?? 0);
    }, [campaignCode, discountRate, discountAmount, minimumSpend]);

    useEffect(() => {
        if (!emailOnlyDiscount) {
            setValue("maxRedeemed", 0);
        }
        if (emailOnlyDiscount) {
            setValue("offerToNewCustomers", false);
        }
        if (offerToNewCustomers) {
            setValue("emailOnlyDiscount", false);
        }
    }, [emailOnlyDiscount, offerToNewCustomers]);

    useEffect(() => {
        if (minimumSpend === undefined || Number(minimumSpend) < 0) {
            setValue("minimumSpend", 0, { shouldValidate: true, shouldDirty: false});
        }
    }, [minimumSpend]);

    useEffect(() => {
        if (!!discountRate && discountRate !== "0") {
            setValue("discountAmount", 0, { shouldValidate: true });
        }
        if (!!discountAmount && discountAmount !== "0") {
            setValue("discountRate", 0, { shouldValidate: true });
            setValue("minimumSpend", Number(discountAmount) * 2, { shouldValidate: true, shouldDirty: true });
            trigger("minimumSpend");
        }
        else {
            setValue("minimumSpend", 0, { shouldValidate: true, shouldDirty: false });
        }
    }, [discountRate, discountAmount]);    

    return (
        <>
            {/** MVP 2 */}
            {/*     
                <RHRadioButtonGroup
                activeThemeColor="purple"
                name="discountType"
                formLabel={translate("discountType")}
                control={control}
                rounded={20}
                value={CAMPAIGN_DISCOUNT_TYPE.GROUP}
                wrapperProps={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "baseline",
                    mb: isGroupDiscount ? "0" : 10
                }}
            >
                <CustomRadioButton value={CAMPAIGN_DISCOUNT_TYPE.GROUP}>
                    {translate(CAMPAIGN_DISCOUNT_TYPE.GROUP)}
                </CustomRadioButton>
                <CustomRadioButton value={CAMPAIGN_DISCOUNT_TYPE.INDIVIDUAL}>
                    {translate(CAMPAIGN_DISCOUNT_TYPE.INDIVIDUAL)}
                </CustomRadioButton>
            </RHRadioButtonGroup> */}
            {/*             {isGroupDiscount ? (
                <FormLabel display="flex" justifyContent="flex-end" fontSize="0.7rem" mb={4}>
                    {"* Group: One code for everyone"}
                </FormLabel>
            ) : null}
            */}
            <RHFormInput
                name="campaignCode"
                formLabel={`${translate("discountCode")}`}
                control={control}
                maxLength={20}
                isFullWidth
                isUpperCase
                tooltipLabelText={translate("discountCodeExample")}
                showExtendedLabelText
            />
            <RHCheckBoxInput
                name="offerToNewCustomers"
                control={control}
                wrapperProps={{ mb: 2 }}
                isDisabled={emailOnlyDiscount}
            >
                {`${translate("allowCampaignCustomers")} *`}
            </RHCheckBoxInput>
            <Text m={0} p={0} color={"yellow.800"} fontSize={"sm"} ml={4} mb={4}>
                {`* ${translate("growCustomerBase")}`}
            </Text>
            <RHCheckBoxInput
                name="emailOnlyDiscount"
                control={control}
                wrapperProps={{ mb: 2 }}
                isDisabled={offerToNewCustomers}
            >
                {`${translate("limitTheDiscountToCustomer")} * `}
            </RHCheckBoxInput>
            <Text m={0} p={0} color={"yellow.800"} fontSize={"sm"} ml={4} mb={4}>
                {`* ${translate("emailOnlyExplanation")}`}
            </Text>

            {emailOnlyDiscount && (
                <RHSelectInput
                    name="maxRedeemed"
                    control={control}
                    formLabel={`${translate("maxUsagePerUser")} ${translate("zeroIsUnlimited")}`}
                    options={maxRedeemedSelectOptions}
                    isDisabled={!emailOnlyDiscount}
                    wrapperProps={{ marginTop: 8 }}
                />
            )}

            <Grid gap="1em" gridTemplateColumns="1fr 1fr">
                <RHSelectInput
                    name="discountRate"
                    formLabel={`${translate("discount")} (%)`}
                    control={control}
                    options={discountRateSelectOptions}
                    wrapperProps={{ marginTop: !emailOnlyDiscount ? 8 : 0 }}
                    isMandatory={discountAmount === "0"}
                    isDisabled={discountAmount !== "0"}
                />
                <RHSelectInput
                    name="discountAmount"
                    formLabel={`${translate("discountAmount")}`}
                    control={control}
                    options={getDiscountAmountSelectOptions(companyLocale)}
                    wrapperProps={{ marginTop: !emailOnlyDiscount ? 8 : 0 }}
                    isMandatory={discountRate === "0"}
                    isDisabled={discountRate !== "0"}
                />
            </Grid>


            <RHFormInput
                name="minimumSpend"
                formLabel={`${translate("minimumSpend")}`}
                control={control}
                defaultValue={0}
                type="number"
                min={0}
                isFullWidth
                tooltipLabelText={translate("minimumSpendLabel")}
                showExtendedLabelText
                isMandatory={discountAmount !== "0"}
                helperText={
                    (discountAmount !== "0" && minimumSpend && Number(minimumSpend) < Number(discountAmount) * 2) ?
                    translate("minimumSpendCheck") : ""}
            />

            <CampaignWizardButtons isValid={isStepValid && isValid} />
        </>
    );
};
